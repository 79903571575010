import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>

              <h4 className="text-sm">Cancer Woman</h4>
              <h4 className="mx-2 text-sm"></h4>


            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer Woman </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Cancer Woman</p> <br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Traits:</p>Tough and soft, loyal, overreact, diplomatic, many factors, emotional and intuitive, selfless.
  <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Compatibility Signs for Cancer men:</p>Taurus, Virgo, Scorpio, Capricorn, and Pisces. <br/><br/>
 Cancer women are all about emotions and feelings. Deep feelings and intuitions rule their personalities. You seek emotional security and an urge for genuineness and honesty in building up a relationship with others. <br/><br/>
  
 The Moon rules you, and hence your mood keeps on changing very quickly, just like different phases of the Moon.<br/><br/>
  
 Cancer women are about the practical, the real, the plain, and the down-to-earth. Cancers are all about emotions and feelings, but they are not all about them. The importance of cancer women's emotional energy is paramount. They need to be acknowledged and valued for their ability to connect with others, make decisions, put their thoughts to practice, and contribute to their children's lives.<br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">You are also motivated to be your best. Your challenges include:</p><br/><br/>
 Establishing boundaries where you are not always comfortable.<br/><br/>
 Being in a learned but impulsive state to the point of selfishness.<br/><br/>
 Knowing that you have to maintain control of your emotions.<br/><br/>
 Yet, you'll have to work hard to keep these feelings to yourself and keep them from the guys you date.<br/><br/>
 However, your needs are not in conflict with your partner's. The key is to be aware of your own needs and your partner's, and then be mindful of the source of your needs and feelings.
  <br/><br/>
  
 You can be emotional, stubborn, compliant, fierce, angry, volatile, passive, and dutiful at the same time. You love to be in your shell. Hence, it is hard to spot you out in a crowd. You are a home seeker and spend the utmost time and energy at home. You are persistent, challenging, and strong-willed but prefer to be in your comfort zone when disturbed or hurt. 
  <br/><br/>
 You give priority to all kinds of securities and possess excellent money-making skills. 
  <br/><br/>
 You will take care of your close ones during their good and bad times. Your horoscope is an effective tool for comprehending your qualities, relationships, and love compatibility on a deeper level. 
  <br/><br/>
  
 If you want to know more about your personality, get back to our best astrologers for personalized janampatri to discover your inner self. 
  
  <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Let's take a look at Cancer women's traits.</p><br/>
 <p className="playfair text-black text-xl md:text-3xl">Tough as well as soft</p> <br/>
 You are problematic from the outside but very soft and gentle from the inside. You are peaceful and easy-going but can become a fighter when time and situations demand it.<br/><br/>
 You are a good caregiver and a good friend. You can take it easy, but you are a hard worker and a perfectionist when you have to. You are solid but soft-hearted. You are soft and easy-going but firm when it comes to discipline. You are all in your way. You are always ready to do what needs to be done.
  <br/><br/>
  
  <p className="playfair text-black text-xl md:text-3xl">Loyal</p><br/>
 You are very loyal in your relationships. You handle family responsibilities perfectly and do everything to protect them. You are incredibly patient and considerate with your parents. You are honest, and you are not afraid to share your opinion. You are romantic and affectionate in your relationships with others, and you love to be with them. You are honest and authentic to yourself.
  <br/><br/>
  
  <p className="playfair text-black text-xl md:text-3xl">Overreact</p><br/>
 You are a great leader, a wonderful friend, and an inspirational conversationalist. But on the flip side, you tend to overreact to situations and issues. <br/><br/>
 You may have an inferiority complex and like to be in your shell. You are quick to close your shot if insulted and beat yourself up over it. You tend to live in your innovative world and memories. It is challenging for you to pin down your memories.
  <br/><br/>
  
  <p className="playfair text-black text-xl md:text-3xl">Diplomatic</p><br/>
 You are a person who is afraid of getting angry and never ready to hit back. You hate involving yourself in fights and issues. 
  <br/><br/>
 You are a peacemaker and stay as a diplomat. You are a person who is more emotional than logical, and if you are careful, you can be the first to get to the punch line of a joke. But at the time, you don't hesitate to do the same when you are standing up in support of your closed one-sided argument.
  <br/><br/>
  
  <p className="playfair text-black text-xl md:text-3xl">Many factors</p><br/>
 You possess many factors, including shyness and being an introvert to a desire to be center stage. <br/><br/>
 You are sensitive, empathetic, passionate, romantic, and kind. At the same time, you are creative, eccentric, and flamboyant. You win over the people around you with your goodness, morals, and family values.
  <br/><br/>
  
  <p className="playfair text-black text-xl md:text-3xl">Many factors</p><br/>
 You possess many factors, including shyness and being an introvert to a desire to be center stage. <br/><br/>
 You are sensitive, empathetic, passionate, romantic, and kind. At the same time, you are creative, eccentric, and flamboyant. You win over the people around you with your goodness, morals, and family values.
  
  <br/><br/>
  <p className="playfair text-black text-xl md:text-3xl">Emotional and intuitive</p><br/>
 You get emotionally nervous and upset when your past haunts your heart. You are sensitive and sympathetic not only to your needs but also to the need of others. You are profoundly intuitive and possess extraordinary imaginative powers. 
  <br/><br/>
 You get instincts when something terrible will happen, even if you don’t know the reason for the same. Your intuition indeed comes true sooner or later. You trust someone based on your instincts.
  <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Selfless</p><br/>
  
 You sacrifice everything for your close ones. You are selfless in your love and keep on giving everything until the point you don’t have anything left. You may face trouble due to this as you don’t focus on yourself and refresh yourself regularly.
  <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How is Cancer a woman in love and relationships?</p><br/>
 Cancer women are in love, and relationships have high expectations about how their partner should demonstrate his emotions. You are passionate, sensitive, affectionate, and caring in love- relationships. You consistently try to be with a man who can thoroughly understand your emotions and sentiments and empathize with you. You have an urge for emotional support and the bonding of souls. 
  <br/><br/>
 It would be best to have security, devotion, and courage from your close ones and partner. You are too introverted to express your feelings aloud. Are you scared to open up about feelings and need a way to convey them? <br/><br/>
 Connect to our astrologers to know the right time and the way to let the butterflies in your stomach fly out.
  
  
  <br/><br/>
  <p className="playfair text-black text-xl md:text-3xl">How To Attract Cancer Women?</p><br/>
 It is not easy to attract a Cancer woman as she is susceptible, does not take criticism healthily, and memorizes the most nuanced experience. Her moods keep changing in a few moments, and she always has an urge for protection, loyalty, and dedication. 
 <br/><br/>
 Here are a few suggestions that will help catch and impress Cancer women.<br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Are you ready to discover it?</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2"></p>Be traditionally romantic: You should have a traditional method to love as she accentuates traditional values the most. You should be honest, loyal, and faithful to her. She doesn't bother about physical appearance when she is in love, and Looks are the least important to her. You can plan a candlelight dinner and take her on a special treat. It would be best if you remember memorable occasions in her life and her family life. 
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Be gentle and reassuring:</p>Cancer women always have an urge for safety and security. You can have a healthy relationship with her if you let her know how important she is in your life and where she stands. She should clearly know about your sobriety and preferences in a love relationship. You need to chase her continuously and keep her in a loop.
 <br/><br/>
 
 <p className="font-bold text-orange-500 inline-block mr-2">Make the first move:</p>First, you should take the initiative and introduce yourself. It would be best if you were overly aggressive in your approach. It would be best if you did not invade in her personal space. Start with a usual chatter with your friends, and then open up your feelings once she is comfortable.
 <br/><br/>
 
 Ask her about the roots: Family comes first for Cancer women. It would be best if you asked about family background and their wellness. You should talk about your family and tell her how much your family is essential to you. Share your family's adventures, experiences, and memorable memories, and ask her for the same. You should get close to her family and win their hearts.
<br/><br/> 
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
